<template>
	<div :class="{ contain: true, mobile: isMobile }">
		<div class="infos d-flex flex-column flex-lg-row">
			<div class="main d-flex flex-column">
				<div class="card_box mr-lg-15">
					<img class="mainimg" v-if="!productPic && !videoPic" />
					<img v-else-if="!videoPic" class="mainimg" :src="productPic" @click="openLargeImg" alt="" />
					<video v-else class="mainimg" ref="video" autoplay loop controls muted controlslist="nodownload"
						:src="videoPic" @click="openLargeImg"></video>
				</div>
				<div class="txt_art-mobile" v-if="isMobile">
					<span>{{ marketNftInfo.name }}</span>
					<p class="flex-start-center" v-html="marketNftInfo.description">
					</p>
				</div>
			</div>
			<div class="info_box d-flex flex-column">
			<div class="ava d-flex flex-row justify-space-between mb-6 ">
					<div class="d-flex flex-row">
						<Avatar :size="42" :did="marketNftInfo.nftOwner" imgType="middlev"
            :grade="marketNftInfo.grade" :hash="marketNftInfo.profilePhoto" showAvatarInfo/>
					<div class=" ml-2 d-flex flex-center mt-lg-3 ownerName">
						 {{ $t("owner_by") }}<span class="name" @click="toOwnerPage">{{
								marketNftInfo.userName ?marketNftInfo.userName:marketNftInfo.nftOwner.substr(0, 3) + "..." + marketNftInfo.nftOwner.substr(-3)  | emptystr
						}}</span>

					</div>
					<!-- <div style="position:absolute;margin-left:90px;margin-top:45px;z-index:1">
						<CreatorAvator  :did="marketNftInfo.owner"  v-if="isShowOwner"/>
					</div> -->
					</div>			
						<div class="countdown mt-lg-3 ml-lg-3 ml-0 d-flex flex-row">
								<img v-if="this.$store.state.did &&marketNftInfo.onsaleOwner != this.$store.state.did" 
						width="20px" height="20px" src="@/assets/icons/btn_message.png" alt="" @click="msgBtn" />
							<div style="display: inline-block" @click="favoriteBtn">
								<img v-if="!isStar" src="@/assets/icons/icon_favorite.png" alt="" />
								<img v-if="isStar" src="@/assets/icons/icon_favorite1.png" alt="" />
							</div>
							<div style="display: inline-block;position:relative" @click="likeBtn">
								<img v-if="!isLike" src="@/assets/icons/icon_like.png" alt="" />
								<img v-if="isLike" src="@/assets/icons/icon_like1.png" alt="" />
								<span v-if="marketNftInfo.likeCount >0"
									style="display:inline-block;position:absolute;bottom:-9px;left:-3px;width:26px;text-align:center;font-size: 9px;font-weight: bold;">
									{{ marketNftInfo.likeCount }}
								</span>
							</div>
							<img src="@/assets/icons/icon_share.png" @click.stop="shareBtn" alt="" />
						
							<div class="share" v-if="openShare">
								<div class="d-flex flex-row mt-lg-5 mt-5 pointer" :class="{ click: isCopy }"
									@click="share('copyLink', '')">
									<img class="mx-5" src="@/assets/icons/icon_IPFS.png" alt="Copy Link" />
									<span class="ml-0">
										{{ $t("carddetailShareCopyLink") }}
									</span>
								</div>
								<div class="d-flex flex-row mt-lg-6 mt-6 pointer" @click="share('twitter', sharePath)">
									<img class="mx-5" src="@/assets/icons/icon_twitter.png" alt="Tweet" />
									<span class="ml-0">Twitter</span>
								</div>
								<div class="d-flex flex-row mt-lg-6 mb-lg-4 mt-6 mb-4 pointer"
									@click="share('facebook', sharePath)">
									<v-icon class="mx-lg-5 ml-5 icon" color="primary">
										mdi-facebook
									</v-icon>
									<span class="ml-lg-0 ml-6 pr-7">Facebook</span>
								</div>
								 
							</div>
							<div class="showtip"  v-if=" this.$store.state.did &&marketNftInfo.onsaleOwner != this.$store.state.did">
										<img
										class="hovershow"
										src="@/assets/icons/icon_complaint.png"
										alt=""
										@click.stop="mousedComplain"
									
										/>
										<div class="box" v-if="clickedComplain">
											<div class="text">
											<div @click="hideNfts('nfts')">{{$t('complain_nfts')}}</div>
											<div @click="hideCreator('creator')">{{$t('complain_creator')}}</div>
											<div @click="nftPlaintBtn" >{{$t('complain_works')}}</div>
											</div>
											
										</div>
							</div>
							
						</div>
				</div>
				<div>
					<div v-if="marketNftInfo.useType == 5 &&  marketNftInfo.couponTime">
						<div class="iris" style="font-weight: bold"  >
								{{  marketNftInfo.couponTime.deduction }} {{ getChainNameByDenom(marketNftInfo.coinDenom )}}
							</div>
							<div
							class="irisOriginalPrice mt-n2"
							style="font-weight: bold"
							>
							{{ marketNftInfo.onsalePrice  }} {{ getChainNameByDenom(marketNftInfo.coinDenom ) }}
							</div>
					</div>
					<div class="iris" style="font-weight: bold" v-else-if="marketNftInfo.onsalePrice && marketNftInfo.saleStatus == 1">
						
					<div class="d-flex flex-lg-row">
						
							<div class="iris" style="font-weight: bold" v-if=" !couponPrice" >
								{{  marketNftInfo.onsalePrice }} {{ getChainNameByDenom(marketNftInfo.coinDenom ) }}
							</div>
							<div class="iris" style="font-weight: bold" v-if=" couponPrice" >
								{{  marketNftInfo.couponVo.deduction  }} {{ getChainNameByDenom(marketNftInfo.coinDenom ) }}
							</div>
							<div
							class="irisOriginalPrice ml-5"
							style="font-weight: bold"
							v-if=" couponPrice"
							>
							{{ marketNftInfo.onsalePrice  }} {{ getChainNameByDenom(marketNftInfo.coinDenom ) }}
							</div>
						</div>
						
						
						
					</div>
					<div class="iris" style="font-weight: bold" v-else-if="marketNftInfo.issuerPrice && marketNftInfo.sellout">
						{{ marketNftInfo.issuerPrice }} {{ getChainNameByDenom(marketNftInfo.coinDenom ) }}
					</div>
					<div class="iris" v-else> {{ $t("NotListedYet") }} </div>
					  <div class="d-flex flex-lg-row">
            <div class="usd" v-if="marketNftInfo.onsalePrice" style="color: #766983">
              USD {{ usd }}
            </div>
            <div
              class="usdOriginalPrice ml-7"
              style="color: #766983"
              v-if="marketNftInfo.onsalePrice && couponPrice"
            >
              USD {{ fixUsd }}
            </div>
          </div>
			
				</div>

		 <div class="handle d-flex flex-row" style="margin-top: 15px">
          <div v-if="owerfrom != 'my'">
            <div v-if="!marketNftInfo.sellout">
              <div class="title" style="font-weight: bold">
                {{ $t("saledetailAvaiable") }} {{ marketNftInfo.amount }}
              </div>
			   <v-btn
                v-if=" marketNftInfo.onsaleOwner == this.$store.state.did || marketNftInfo.saleStatus == 0"
                readonly
                class="bid-btn mt-3 mt-lg-3 sale_out"
                >{{ $t("popupBuyPriceBuyNow") }}</v-btn
              >
              <v-btn
                v-else-if="marketNftInfo.onsalePrice != 0 && !marketNftInfo.exclusivesInfoList &&marketNftInfo.owner != this.$store.state.did && marketNftInfo.saleStatus != 0 && !isMobile && marketNftInfo.amount > 0 "
                class="uc bid-btn mt-3 mt-lg-3 nosale_out"
                @click="buybtn"
                >{{ $t("popupBuyPriceBuyNow") }}</v-btn
              >
			    <v-btn
                v-else-if="marketNftInfo.onsalePrice != 0 && !marketNftInfo.exclusivesInfoList &&marketNftInfo.owner != this.$store.state.did && marketNftInfo.saleStatus != 0 && isMobile "
                class="uc bid-btn mt-3 mt-lg-3 nosale_out"
                @click="buyBtn"
                >{{ $t("popupBuyPriceBuyNow") }}</v-btn
              >



              <v-btn
                v-else-if="marketNftInfo.onsalePrice != 0 && haveExclusive && !isMobile "
                class=" uc bid-btn mt-3 mt-lg-3 nosale_out"
                @click="buybtn"
                >{{ $t("popupBuyPriceBuyNow") }}</v-btn
              >
			   <v-btn
                v-else-if="marketNftInfo.onsalePrice != 0 && haveExclusive && isMobile"
                class=" uc bid-btn mt-3 mt-lg-3 nosale_out"
                @click="buyBtn"
                >{{ $t("popupBuyPriceBuyNow") }}</v-btn
              >
              <v-btn
                v-else-if="marketNftInfo.onsalePrice != 0 && !haveExclusive  "
                readonly
                class="bid-btn mt-3 mt-lg-3 sale_out"
                >{{ $t("popupBuyPriceBuyNow") }}</v-btn
              >
              <v-btn
                v-else
                class="bid-btn mt-3 mt-lg-3 nosale_out"
                @click="buybtn"
                >{{ $t("getForFree") }}</v-btn
              >
            </div>
         
          </div>
          <img
            class="pointer phone ml-4 mt-lg-13 mt-14"
            :src="phoneBtn"
            alt="button"
            width="20px"
            height="31px"
            @click="buyBtn"
          />
        </div>
		<div class="preferentialInfo d-flex flex-row" v-if="marketNftInfo.marketType == '5'&&  marketNftInfo.couponTime">
			<div class="img">
				<img src="@/assets/icons/icon_time.png"  width="40px"
            height="40px" alt="">
			</div>
			<div class="contant">
				<div class="time">{{startTimer}} ~ {{endTimer}}</div>
				<div class="des">{{$t('LimitedTime')}}:  {{$t('LimitedTime_des')}} </div>
			</div>
		</div>

				 <div class="bindCard mb-6 ml-n2 mt-6">
          <div class="d-flex flex-row">
            <div
              class="second d-flex flex-row ml-3 align-center"
              v-if="isFirst"
            >
              <img
                class="icon"
                src="@/assets/image/icon_exclusive.png"
                alt=""
              />

              <div>
                <div class="bk_title" v-html="title"></div>
                <div class="name" v-html="exclusiveName"></div>
              </div>

              <img
                class="img ml-2"
                v-if="haveExclusive"
                src="@/assets/icons/icon_yes1.png"
                alt=""
              />
            </div>
          </div>

          <div class="d-flex flex-row">
            <div
              class="second d-flex flex-row mt-1 ml-3 align-center"
              v-if="isSecond"
            >
              <img class="icon" src="@/assets/image/icon_discount.png" alt="" />
              <div>
                <div class="bk_title" v-html="title"></div>
                <div class="name" v-html="discountName"></div>
              </div>
              <img
                class="img mr-2 ml-3"
                v-if="haveCoupon"
                src="@/assets/icons/icon_yes1.png"
                alt=""
              />
            </div>
            <!-- <img
              class="yes ml-2 mt-5"
              v-if="haveCoupon"
              src="@/assets/image/icon_yes.png"
              alt=""
            /> -->
          </div>
          <div class="cardListbox d-flex flex-wrap">
            <div
              class="cards d-flex flex-row align-center"
              v-for="(item, index) in cardList"
              :key="index"
              @click="onClickImg(item)"
            >
              <img class="cardimg" :src="item.src" alt="" />
              <div class="txt ml-3">{{ item.text }}</div>
              <img
                v-if="item.is_yes == true"
                class="isYes"
                src="@/assets/image/icon_yes.png"
                alt=""
              />
            </div>
          </div>
        </div>


	<div v-if="contractCollectionVO != null">
		
				<div class="collect" v-if="contractCollectionVO.name" @click="collectClick(contractCollectionVO)">
					<img class="img" :src="getImageIpfs(contractCollectionVO.collectionImgUrl)" />
					 <img class="complaint" v-if="(contractCollectionVO.contractStatus == 2 || contractCollectionVO.contractStatus == 4) " src="@/assets/icons/ban.png" width="20px" height="20px" alt=""    />
					<div class="info">
						
						<span class="dect">{{ contractCollectionVO.name }}</span>
						<span class="num">{{ contractCollectionVO.nftCount }} Items</span>
					</div>
				</div>
				
				
					
				</div>
				<div class="  d-flex flex-center mt-lg-3 createName" v-if="marketNftInfo.channel == 'IRISnet'">
					 {{ $t("created_by") }}<span class="name" @mouseenter="mousedCreate()">{{
					marketNftInfo.creatorName?marketNftInfo.creatorName:(marketNftInfo.owner).substr(0,6)+"..."+(marketNftInfo.owner).substr(-6) | emptystr
					}}</span>
					<div style="position:absolute;margin-left:90px; margin-top:20px;z-index: 10;">
					<CreatorAvator  :did="marketNftInfo.creatorOwner"  v-if="isShowAva"/>
				</div>
				</div>
				<div v-else>
  		<div class="createName mb-3">
			{{$t('originally_by')}} <span class="name" @click="toMarketplacePage(marketNftInfo.creator)" > {{marketNftInfo.creator.substr(0,6) + "..." + marketNftInfo.creator.substr(-6)}}   </span>  {{$t('originally_on')}}  {{marketNftInfo.channel}}
        </div>
       
        
</div>
				
					<div  class="d-flex flex-row contract" style="position: relative;">
					<div class="  d-flex flex-center createName" style="margin-top:-4px;">
					 {{ $t("Contract") }} <a :href="this.addressExploer" target="_blank" class="name" style="text-decoration: none;">{{ (marketNftInfo.contractAddress ).substr(0,10)+"..."+(marketNftInfo.contractAddress ).substr(-10)}}</a>		
						<img v-if="marketNftInfo.disabled==1" style="height:20px;width:20px;margin-left:10px;" src="@/assets/icons/officially_verified.png" alt=""  @mouseenter="mousedContract()" @mouseleave="leaveContract()"/>
				</div>
			
					 <div
           		 class="explaincontract d-flex flex-row align-center"
            		v-if="isshowContract"
         			 >
          		  <span
            			  >{{ $t("Officially")}}
           		 </span>
          		</div>
				
				</div>
				<!-- <div class=" d-flex flex-center mt-lg-n1" v-if="marketNftInfo.channel" style="font-size:14px;!important">
					 {{ $t("saledetailHistoryForm") }} {{marketNftInfo.channel}}
				
				</div> -->




		
				<div class="d-flex flex-row  mt-2">
					<div class="current-bid  d-flex flex-row ">
					<img src="@/assets/icons/icon_IPFS.png" alt="" />
					<a class="ml-4 mt-1" :href="this.IpfsHub" target="_blank"  @click="toIpfs">{{
							$t("saledetailViewOnIpfs")
					}}</a>
					
				</div>
				<div class="current-bid d-flex flex-row ml-10">
					<img src="@/assets/icons/icon_metadata.png" alt="" />
					<a class="ml-4 mt-1" :href="this.IpfsMetadata"  @click="toMetadata" target="_blank">{{$t("view")}} Metadata</a>
					
				</div>
				</div>

					   <div class="promotion" v-if="promotionList.length > 0 && marketNftInfo.level == '2'">
          <div class="contant" >
               <div class="participateTitle">participate in promotions:</div>
               <div class="promotionList d-flex justify-space-between" v-for="(item,index) in promotionList" :key="index" >
                 <div class="name">{{item.contractName}}</div>
                 <div class="used" v-if="item.isUsed">{{$t('used')}}</div>
                <div class="unused" v-else>{{$t('unused')}}</div>
               </div>
          </div>
		   </div>
				
			</div>
		</div>
		<div class="txt_art" v-if="!isMobile">
			<div class="art-title">{{ marketNftInfo.name }}</div>
			<div class="art-description" v-html="marketNftInfo.description">
			</div>
		 <div class="title mt-7" v-if="PropertyList&&PropertyList.length>0">{{ $t("Properties_title")}}</div>
        <div v-for="(item,index) in PropertyList" :key="index" class="d-flex flex-column">
          <div class="d-flex flex-row">
            <div class="Properties">{{item.trait_type}}</div>
             <div class="PropertiesValue " > {{item.value}}</div>
          </div>
        </div>
		<div class="lincenseimg mt-4" v-if="marketNftInfo.lincenseCode == 'CC0'">
			<img src="@/assets/icons/cc-zero.png" @mouseenter="mousedlincense()" @mouseleave="leavelincense()"
                alt="" />
			<div class="explainlincense d-flex flex-row align-center" v-if="isshowLincense" >
                <span>{{
					$t("CC0_explain")
					}}</span>
              </div>
		</div>
		</div>
		   <!-- 跨链记录 -->
		   <div class="table">
      <div class="mb-7 mt-7 tab_tit">{{ $t("cross_transfer") }}</div>
      <v-data-iterator
        v-if="crossChianList.length > 0"
        class="table_cont"
        :items="crossChianList"
      >
        <template v-slot:header>
          <div
            class="table_font d-flex justify-space-around mb-1 mt-4 py-3 px-lg-10 elevation-0"
            style="border: 1px solid #cccccc;border-radius: 5px;"
          >
            <div style="width: 21%; font-weight: bold">
              {{ $t("saledetailHistoryForm") }}
            </div>
            <div style="width: 42%; font-weight: bold">
              {{ $t("saledetailHistoryTo") }}
            </div>
          
            <!-- <div style="width: 21%;font-weight:bold;">
                     {{ $t("saledetailHistoryTranscation") }}
                  </div> -->
            <div style="width: 21%; font-weight: bold">
              {{ $t("detailIndexTime") }}
            </div>
          </div>
        </template>
        <template v-slot:default="{ items }">
          <div
            class="table_item_font d-flex justify-space-around mb-1 py-2 elevation-0"
            style="border: 1px solid #cccccc;border-radius: 5px;"
            v-for="(item) in items"
            :key="item.id"
          >
            <div style="width: 20%;font-size:14px;" class="item_font ml-lg-12 mr-1 pt-lg-1">
				<img  :src="item.fromChainIcon"  width="18" height="18" alt="">
              <span class="ml-3 item_font item_fonts" >{{ item.fromChainType }}</span>
            </div>
            <div style="width: 42%;;font-size:14px;" class="d-flex flex-row mr-1 fom">
				<img  :src="item.toChainIcon"  width="18" height="18" alt="">
              <span class="ml-3 item_font item_fonts" style="">{{ item.toChainType }}</span>
            </div>
          
            <div style="width: 20%;font-size: 14px;margin-top:5px;" class="item_font mr-10">
				{{ timestampToDateTime( Number(item.timespan) )}}
            </div>
          </div>
        </template>
      </v-data-iterator>
      <v-data-iterator
        v-else
        class="table_cont"
        :items="crossChianList"
        hide-default-footer
      >
        <template v-slot:header>
          <div
            class="table_font d-flex justify-space-around mb-1 mt-8 py-3 px-lg-10 elevation-1"
          >
            <div style="width: 21%; font-weight: bold">
              {{ $t("saledetailHistoryForm") }}
            </div>
            <div style="width: 42%; font-weight: bold">
              {{ $t("saledetailHistoryTo") }}
            </div>
         
            <div style="width: 21%; font-weight: bold">
              {{ $t("detailIndexTime") }}
            </div>
          </div>
        </template>
        <template v-slot:default="{ items }">
          <div
            class="table_item_font d-flex justify-space-around mb-1 py-2 elevation-1"
            v-for="item in items"
            :key="item.time"
          >
            <div style="width: 20%" class="item_font ml-lg-7 mr-1">
				<img  :src="item.fromChainIcon"  width="18" height="18" alt="">
              <span class="ml-3 item_font item_fonts">{{ item.fromChainType }}</span>
            </div>
            <div style="width: 42%" class="d-flex flex-row mr-1 fom">
				<img  :src="item.toChainIcon"  width="18" height="18" alt="">
              <span class="ml-3 item_font item_fonts" style="margin-top:5px;">{{ item.toChainType }}</span>
            </div>
            <div style="width: 20%" class="item_font mr-10">
				{{ timestampToDateTime( Number(item.timespan) )}}
            </div>
          </div>
        </template>
      </v-data-iterator>
    </div>
		<div class="history_sale mt-lg-8 mt-8">
			<span>{{ $t("saledetailHistory") }}</span>
			<v-data-iterator v-if="desserts.length > 0" :items="desserts" no-data-text="">
				<template v-slot:header>
					<div class="
              d-flex
              justify-space-around
              mb-1
              mt-8
              py-2
              pl-lg-10
              elevation-0
            " style="border: solid 1px #cccccc;height:50px;font-weight:bold;border-radius: 5px;">
						<div style="width: 15%">{{ $t("saledetailHistoryEvent") }}</div>
						<div style="width: 15%">{{ $t("saledetailHistoryPrice") }}</div>
						<div style="width: 16%">{{ $t("saledetailHistoryForm") }}</div>
						<div style="width: 14%">{{ $t("saledetailHistoryTo") }}</div>
						<div style="width: 15%">{{ $t("detailIndexTime") }}</div>
					</div>
				</template>
				<template v-slot:default="{ items }">
					<div class="
              table_item_font
              d-flex
              justify-space-around
              mb-1
              py-2
              px-lg-10
              elevation-0
            " v-for="(item, index) in items" :key="index" style="border: solid 1px #cccccc;height:50px;border-radius: 5px;" >
						<div class="overflow item_font" style="width: 15%;">
							{{ item.event }}
						</div>
						<div class="overflow item_font ml-4"   v-if="!isMobile" style="width: 15%;font-size:14px;">
							{{ item.price || '—'   }}
						</div>
						<div class="overflow item_font ml-4" v-else style="width: 20%;">
							{{ item.price || '—'   }}
						</div>
						<div class="overflow d-flex flex-row" style="width: 16%">
							<v-avatar size="30" style="overflow: visible; position: relative">
								<v-img v-if="item.formavatarSrc == 'null'" src="@/assets/default.png"
									:alt="item.form" />
								<v-img v-else :src="item.formavatarSrc" :alt="item.form" />
								<img
									v-if="item.fromGrade == 3"
									src="@/assets/icons/icon_v.png"
									alt="V"
									style="
										width: 15px;
										height: 15px;
										position: absolute;
										right: -4px;
										bottom: 1px;
									"
									/>
							</v-avatar>
							<span class="ml-3 item_font item_fonts" style="font-size:14px;">{{ item.form }}</span>
						</div>
						<div class="overflow d-flex flex-row" style="width: 14%">
							<v-avatar size="30" style="overflow: visible; position: relative">
								<v-img v-if="item.toavatarSrc == 'null'" src="@/assets/default.png" :alt="item.form" />
								<v-img v-else :src="item.toavatarSrc" :alt="item.form" />
								 <img
										v-if="item.toGrade == 3"
										src="@/assets/icons/icon_v.png"
										alt="V"
										style="
											width: 15px;
											height: 15px;
											position: absolute;
											right: -4px;
											bottom: 1px;
										"
										/>
								<!-- <v-img :src="item.toavatarSrc" :alt="item.to" /> -->
							</v-avatar>
							<span class="ml-3 item_font item_fonts" style="font-size:14px;">{{ item.to }}</span>
						</div>
						<div class="overflow item_font item_fonts" v-if="!isMobile" style="width: 15%;font-size:14px;">
							{{ item.time }}
						</div>
						<div class="overflow item_font item_fonts" v-else style="width: 20%;font-size:14px;">
							{{ item.time }}
						</div>
					</div>
				</template>
			</v-data-iterator>
		</div>

		<div class="sale_card" v-if="Nft_skuList.length != 0">
			<div class="sale_tit">
				<Avatar :size="42" :did="marketNftInfo.owner" :hash="marketNftInfo.profilePhoto" />
				<span style="display: inline-block" class="mb-lg-n9">{{
						marketNftInfo.issuerName
				}}</span>
				<img class="pointer rgh" :src="rightArrow" alt="" width="31px" height="31px" @click="toPage" />
			</div>
			<div class="cardbox">
				<CardList :list="Nft_skuList" type="other" />
			</div>
		</div>
		<v-dialog fullscreen v-model="openLargemg">
			<large-img ref="largeDialog" v-if="openLargemg" :productPic="productPic" :videoPic="videoPic"
				@getOpen="Open">
			</large-img>
		</v-dialog>
		<v-dialog v-model="openpay">
			<pay @getOpen="OpenPay" :Nft_sku="marketNftInfo"></pay>
		</v-dialog>
		<div v-if="openmsg" style="position: fixed; right: 20px; bottom: 20px; z-index:300">
			<message @getOpen="OpenMsg" :currfriend="currfriend"></message>
		</div>
		<v-dialog v-model="nftComplaint">
			<nft-complaints :Nft_sku="marketNftInfo" @getOpen="openNftcComp" complaintType="commonComplaint"></nft-complaints>
		</v-dialog>
		<v-dialog v-model="openbuy">
			<buy-price v-if="openbuy" :Nft_sku="marketNftInfo" :owerfrom="owerfrom" :haveExclusive="haveExclusive"
				:assetLevel="assetLevel" :buytype="1" :userInfo="userInfo" :buyPrice="buyPrice" :haveCoupon='haveCoupon' @getOpen="openBuy"></buy-price>
		</v-dialog>

		<uComponents ref="ucom"></uComponents>
	</div>
</template>

<script>
	import phoneBtn from "@/assets/icons/icon_pay.png";
	// import productPic from "@/assets/test.png";
	import CardList from "@/components/workCard/CardList.vue";
	import rightArrow from "@/assets/icons/icon_r.png";
	import LargeImg from "../../components/largeImg";
	import Pay from "@/components/popup/pay.vue";
	import message from "@/components/popup/message.vue";
	import nftComplaints from "@/components/popup/nftComplaints.vue";
	import Avatar from "@/components/avatar/index";
	import CreatorAvator from "@/components/creatorAvatar/index";
	import api from "@/api";
	import {
		getFileSrc
	} from "@/utils/file";
	//xxl share

	import { getSettingArr } from "@/utils/helper";

	import BuyPrice from "../../components/popup/buyPrice";
	import {
		share
	} from "@/utils";
	import logoimg from "@/assets/default.png";

	const EXCLUSIVE_KEY = "EXCLUSIVE";
	const WORK_KEY = "WORK";

	export default {
		components: {
			BuyPrice,
			LargeImg,
			CardList,
			Pay,
			message,
			nftComplaints,
			Avatar,
			CreatorAvator
		},
		data() {
			return {
				baseContractAddress:'',
				 promotionList:[ ],
				startTimer:'',
				endTimer:'',
				clickedComplain:false,
				openWallet: false,
				addressExploer:"",
				isshowContract:false,
				buyPrice:'',
				title:'',
				discountName:'',
				  couponPrice: "",
				isFirst: false,
     			 isSecond: false,
     			 haveCoupon: false, //我是否拥有优惠卡
     			 haveExclusive: false, //我是否拥有转卖卡
				currfriend: {},
				openLargemg: false,
				isshowLincense:false,
				openpay: false,
				openmsg: false,
				nftComplaint: false,
				openbuy: false,
				 phoneBtn,
				productPic: "",
				videoPic: "",
				sharePath: "",
				rightArrow,
				isSort: true,
				isLike: false,
				isStar: false,
				openShare: false,
				isCopy: false,
				IpfsMetadata: "",
				IpfsHub: "",
				crossChianList:[],
				PropertyList:[
    

    ],
				headers: [{
						text: "Event",
						value: "event",
						sortable: false
					},
					{
						text: "Price (IRIS)",
						value: "price",
						sortable: false
					},
					{
						text: "Form",
						value: "form",
						sortable: false
					},
					{
						text: "To",
						value: "to",
						sortable: false
					},
					{
						text: "Time",
						value: "time",
						sortable: false
					},
				],
				creator: "profilePhoto",
				desserts: [],
				cards: [],
				marketNftInfo: {},
				Nft_historyList: {},
				Nft_skuList: [],
				Nft_denomid: "",
				address: "",
				price: "",
				assetLevel: 1,
				usd: 0,
				fixUsd: "",
				isSelf: false,
				idNotList: false,
				isShow: false,

				userInfo: {},
				contractCollectionVO: {},
				owerfrom: "",
				isShowAva:false,
				isShowOwner: false,
				isShowHover:false,
				 cardList: [
      ],
			};
		},
		computed: {
			isMobile: function() {
				return this.$vuetify.breakpoint.mobile;
			},
		},
		filters: {
			emptystr: function(value) {
				if (!value || value.indexOf("NaN") >= 0) return " ";
				return value;
			},
		},
		async mounted() {
	
		
	
			this.showNoRead();
			window.addEventListener("click", this.clickOther);
			this.owerfrom = this.$route.query.from;
			let userParam = {
       userAddress: this.$store.state.did,
              chainType: this.$store.state.chainType
        };
        let user = await api.home.createInfo(userParam);
			if (user) {
				this.userInfo = user.data;
			}

			let paramsInfo;

			if(this.$route.query.id){
				 paramsInfo = {
				contractId: this.$route.query.id,
				
				address:this.$store.state.did
			};
			}else{
				 paramsInfo = {
				contractAddress: this.$route.query.contractAddress,
				tokenId: this.$route.query.tokenId == undefined ?'':this.$route.query.tokenId,
				nftOwner: this.$route.query.owner == undefined ?'':this.$route.query.owner ,
				address:this.$store.state.did
			};

			}
			
			let res1 = await api.home.nftTokenInfo(paramsInfo);
		
			this.marketNftInfo = res1.data;
				debugger
			//通过卡详情跳转的拍卖商品
			if(this.marketNftInfo.useType == 1){
				let targetUrl= window.location.href.replace("saledetail","detail");
       			location.href=targetUrl;
			}
			if(this.marketNftInfo.amount == 0)
			this.marketNftInfo.onsalePrice = this.marketNftInfo.assetExtensionVo.issuerPrice

			this.usd = this.getPriceByUsd(this.marketNftInfo.coinDenom,this.marketNftInfo.onsalePrice)
			if(this.marketNftInfo.couponTime){
					this.startTimer = this.timestampToDateHour(this.marketNftInfo.couponTime.couponStartTime)
					this.endTimer = this.timestampToDateHour(this.marketNftInfo.couponTime.couponEndTime)
			}
			if(this.marketNftInfo.couponTime){
				this.buyPrice = this.marketNftInfo.couponTime.deduction;
			}else{
				this.buyPrice = this.marketNftInfo.onsalePrice;
			}
			// if(res1.data.properties){
			// 	this.PropertyList = JSON.parse(res1.data.properties)
			// }
			if(res1.data.properties == '[{}]'){
				this.PropertyList =[]
			}else{
					this.PropertyList = JSON.parse(res1.data.properties)
			}
			
			if(this.PropertyList)
			this.PropertyList = this.PropertyList.filter((item)=>item.trait_type != '' && item.value != '')
	
		
			//判断是否显示权益卡的卡片
	 if (
      this.marketNftInfo.exclusivesInfoList != null &&
      this.marketNftInfo.exclusivesInfoList.length > 0
    ) {
      this.isFirst = true;

      let t = this.$t("zm_des_or");
      if (this.marketNftInfo.exclusivesType == 1) {
        t = this.$t("zm_des_and");
      }
      this.title = this.$t("zm_title");

      this.exclusiveName = t;
    //   if (this.marketNftInfo.exclusiveImg)
    //     this.exclusiveImgSrc = await getFileSrc(
    //       EXCLUSIVE_KEY,
    //       this.marketNftInfo.exclusiveImg
    //     );

      this.marketNftInfo.exclusivesInfoList.forEach(async (v) => {
        let imgSrc = await getFileSrc(WORK_KEY, v.exclusiveImg);

        this.cardList.push({
          src: imgSrc,
          text: v.exclusiveName,
          is_yes: v.exclusiveIsUsable,
          exclusiveAuctionNo: v.exclusiveAuctionNo,
          exclusiveContractId: v.exclusiveContractId,
          type: "exclusive",
          exclusiveCollectionType:v.exclusiveCollectionType,
          exclusiveTokenId:v.exclusiveTokenId,
          exclusiveContractCollectionId:v.exclusiveContractCollectionId,
		  exclusiveLinkUrl:v.exclusiveLinkUrl,
		  exclusiveOwner:v.exclusiveOwner,
		  exclusiveNftAddress:v.exclusiveNftAddress,
		  exclusiveNftId:v.exclusiveNftId,
		  exclusiveDenom:v.exclusiveDenom
	
        });
      });
    }else{
      this.marketNftInfo.exclusivesInfoList = null
    }
    if (
      this.marketNftInfo.couponVo != null &&
      this.marketNftInfo.couponInfoList != null && this.marketNftInfo.couponInfoList.length>0
    ) {
      this.isSecond = true;

      let t = this.$t("yh_des_or");
      if (this.marketNftInfo.couponVo && this.marketNftInfo.couponVo.type == 1) {
        t = this.$t("yh_des_and");
      }

      let reg = new RegExp("【N】", "g"); //g代表全部

      let price = this.marketNftInfo.couponVo.deduction +' ' +this.getChainNameByDenom(this.marketNftInfo.coinDenom)

      this.title = this.$t("yh_title").replace(reg, price);
      this.discountName = t;
      this.pictureSrc = await getFileSrc(
        EXCLUSIVE_KEY,
        this.marketNftInfo.couponVo.picture
      );
      this.marketNftInfo.couponInfoList.forEach(async (v) => {
        let imgSrc = await getFileSrc(WORK_KEY, v.couponImg);

        this.cardList.push({
          src: imgSrc,
          text: v.couponName,
          is_yes: v.couponIsUsable,
          couponAuctionNo: v.couponAuctionNo,
          couponContractId: v.couponContractId,
          type: "coupon",
          couponCollectionType:v.couponCollectionType,
          couponLinkUrl:v.couponLinkUrl,
		  couponTokenId:v.couponTokenId,
		  couponOwner:v.couponOwner,
		  couponNftAddress:v.couponNftAddress,
		  couponNftId:v.couponNftId,
			couponDenom:v.couponDenom
        });
      });
	}
	
	if (this.isFirst || this.isSecond) {
      if (this.$store.state.did && this.marketNftInfo.listAmount != 0) {

		  let coponParams = {
			    owner: this.marketNftInfo.owner,
            contractAddress: this.marketNftInfo.contractAddress,
			contractId:this.marketNftInfo.contractId,
			address:this.$store.state.did,

		  }
		  if(this.marketNftInfo.collectionType == '1'){
			 coponParams.tokenId = this.marketNftInfo.tokenId 
		  }
	
        api.home
          .getCouponInfoJson(coponParams)
          .then((resn) => {
            if (resn.success && resn.data) {
              if (resn.data.usable) {
                this.haveCoupon = resn.data.usable;
                if (this.haveCoupon && resn.data.usable) {
				  this.couponPrice = this.marketNftInfo.couponVo.deduction;
				  this.buyPrice = this.couponPrice;
                //   this.fixPrice = this.marketNftInfo.couponVo.deduction;
                  let usd_price = localStorage.getItem("KEY_USD_PRICE");
				//   console.log("6666666666666");
				  this.usd = this.getPriceByUsd(this.marketNftInfo.coinDenom,this.couponPrice)
        
                   this.fixUsd = this.getPriceByUsd(this.marketNftInfo.coinDenom,this.marketNftInfo.onsalePrice)
                  
            
                }
              }
              this.haveExclusive = resn.data.hasExclusive;
            }
          });
      }
    }

			if(!this.marketNftInfo.imgUrl){
				this.marketNftInfo.imgUrl="QmPcXBirGiUSyjb8i2FQUpNDTtPHBsgo7tviwwm4YQeJ6p"
			}
			 if (this.marketNftInfo.fileType =='MP4') {

		this.videoPic = await getFileSrc(WORK_KEY, this.marketNftInfo.fileUrl, true);
		this.sharePath = this.videoPic;
      
    }
	if (this.marketNftInfo.description)
				this.marketNftInfo.description = this.marketNftInfo.description.replace(/\n/g, "<br/>");
			if (this.marketNftInfo.contractCollectionVO != 'null') {
				this.contractCollectionVO = this.marketNftInfo.contractCollectionVO

				//this.addressExploer="https://irishub.iobscan.io/#/mto/nfts?denomId="+this.Nft_sku.address.toLowerCase()
				this.addressExploer="https://irishub.iobscan.io/#/mto/nft-denoms/"+this.marketNftInfo.contractAddress.toLowerCase()
			}

	    if(this.marketNftInfo.fileType =='MP4'){
        this.IpfsHub = this.getImageIpfs(this.marketNftInfo.fileUrl);
      }else{
          this.IpfsHub = this.getImageIpfs(this.marketNftInfo.imgUrl);
      }
	  let metadataUrl = this.marketNftInfo.contractAddress.indexOf('ibc')
      console.log("metadataUrl ---111",metadataUrl,this.marketNftInfo.contractAddress)
	  let contarctaddress = this.marketNftInfo.contractAddress
      if(metadataUrl == 0)
      contarctaddress = this.marketNftInfo.contractAddress.replace(
        '/',
        "-"
      ); 
			this.IpfsMetadata= await api.image.getIPFSurl(contarctaddress,this.marketNftInfo.tokenId); 
			let imgurl =  this.marketNftInfo.imgUrl.indexOf("http")
			if(imgurl == -1){
				this.productPic = await api.image.getNftImg(
					this.marketNftInfo.imgUrl
				); 
        }else{
			this.productPic = this.marketNftInfo.imgUrl
		}
		
			await this.initData();
			// 是否收藏
			let params = {
				userAddress: this.$store.state.did,
			 followId: this.marketNftInfo.contractId,
				followType: "1",
				 tokenID:this.marketNftInfo.tokenId,
			}
			let iscollect = await api.home.isCollect(params)
			if (iscollect.data == true) {
				this.isStar = true
			} else {
				this.isStar = false
			}
			params.followType = 4
			let islike = await api.home.isCollect(params)
			if (islike.data == true) {
				this.isLike = true
			} else {
				this.isLike = false
			}
			 // 判断用户是否存在
 
			let userParam1 = {
       userAddress: this.marketNftInfo.creatorOwner,
              chainType: this.$store.state.chainType
        };
        let user1 = await api.home.createInfo(userParam1);
        if(user1.data.createTime == null){
          this.isShowHover = false
        }else{
          this.isShowHover = true
		}
		

		// userLog 查看优惠券使用情况
		if(this.$route.query.tokenId){
				let userLogRes = await api.home.userLog({tokenId:this.$route.query.tokenId
		})
		this.promotionList = userLogRes.data
		}
	
		},
		methods: {
			toMarketplacePage(address){
				//https://testweb.testnet.uptick.network/otherpage?did=0x12c27d405bee4d48cf8ceee24e52b84649a2ab2f
      window.open('https://uptick.upticknft.com/otherpage?did='+address, "_blank");

    },
			toIpfs(){
      this.$gtag.event('NFT详情页-IPFS', { 'event_category': 'Click', 'event_label': 'IPFS' })
    },
    toMetadata(){
      this.$gtag.event('NFT详情页-Metadata', { 'event_category': 'Click', 'event_label': 'Metadata' })
    },
			toOwnerPage(){
			 this.$router.push({
          name: "Otherpage",
          query: { did: this.marketNftInfo.nftOwner },
		});
	// 	   this.$router.push({
    //         name: "ShowCasePage",
    //         query: { did: this.marketNftInfo.owner},
    //   });
			},
			async initData() {

				 // 获取跨链记录
				 let crossChainParams ={
      pageSize: 50,
      pageNumber: 1,
      nftAddress: this.$route.query.contractAddress,
      nftId: this.$route.query.tokenId,
    }
   let crossChianList= await api.home.getCrossListByTokenId(crossChainParams);
   this.crossChianList = crossChianList.data
				let params
				if(this.$route.query.id){
					params = {
					contractId: this.$route.query.id,
				}
				}else{
				 params = {
				contractAddress: this.$route.query.contractAddress,
				}
				}


				
				if(this.marketNftInfo.collectionType == 1){
				params.tokenId = this.$route.query.tokenId
				}
				let res2 = await api.home.assetHistory(
						params
				);
				
				
				this.desserts = [];
				if(res2.data){
				res2.data.forEach((v) => {
					let from = logoimg;
					let to = logoimg;
					if (v.fromImg) {
					  from = this.getImageIpfs(v.fromImg);
					}
					if (v.toImg) {
					  to = this.getImageIpfs(v.toImg);
					}
					let fromName,toName;
					if (v.fromName) {
					  fromName = v.fromName;
					}else{
						if( v.fromAddress){
								fromName = v.fromAddress.substr(0, 5)+"..."+v.fromAddress.substr(-5);
						}else{
							fromName ='—'
						}
					
					}
					if (v.toName) {
					  toName = v.toName;
					}else{
						if(v.toAddress){
						 toName =  v.toAddress.substr(0, 5)+"..."+v.toAddress.substr(-5);
						}else{
							toName ='—'
						}
				
					}
					let price=v.price+" "+ this.getChainNameByDenom(v.coinDenom)
					if(v.price==null){
						price="";
					}
							
					this.desserts.push({
						event: v.event,
						price:price,
						form: fromName,
						to: toName,
						time: this.isMobile ? this.timestampToDate(v.eventTime): this.timestampToDateTime(v.eventTime),
						formavatarSrc: from,
						toavatarSrc: to,
						 fromGrade: v.fromGrade,
         				 toGrade: v.toGrade,
					});
				});
				}

			},
			share,
			async favoriteBtn() {
				 this.$gtag.event('NFT详情页-收藏', { 'event_category': 'Click', 'event_label': '收藏' })
				console.log("wxl --- 2222",this.marketNftInfo);
				let params = {
					followType: 1,
					followId: this.marketNftInfo.contractId,
					userAddress: this.$store.state.did,
					followOwner:this.marketNftInfo.owner,
					

				};
				if( this.marketNftInfo.collectionType == '1'){
					params.tokenId = this.marketNftInfo.tokenId
				}
				let res = await api.home.followDo(params);
				if (res.success === true) this.isStar = !this.isStar;
			},
			async likeBtn() {
				this.$gtag.event('NFT详情页-喜欢', { 'event_category': 'Click', 'event_label': '喜欢' })
				let params = {
					followType: 4,
					followId: this.marketNftInfo.contractId,
					userAddress: this.$store.state.did,
					followOwner:this.marketNftInfo.owner,
				};
				if( this.marketNftInfo.collectionType == '1'){
					params.tokenId = this.marketNftInfo.tokenId
				}
				let res = await api.home.followDo(params);
				if (res.success === true) {
					this.isLike = res.data;
					if (!this.isLike) {

						if (this.marketNftInfo.likeCount <= 1) {
							this.marketNftInfo.likeCount = 0
						} else {
							--this.marketNftInfo.likeCount
						}
					} else {

						if (this.marketNftInfo.likeCount == null) {
							this.marketNftInfo.likeCount = 1
						} else {
							++this.marketNftInfo.likeCount
						}

					}
					if (this.marketNftInfo.likeCount == 0) {
						this.marketNftInfo.likeCount = ''
					}
				}
			},
			shareBtn() {
			 this.$gtag.event('NFT详情页-分享', { 'event_category': 'Click', 'event_label': '分享' })
				this.openShare = true;
			},
			 onClickImg(item) {
				 debugger
      console.log("wxl  11111111",item.couponCollectionType,"ssssssss");
      console.log(item);
      if (item.type == "exclusive") {
        if (item.exclusiveAuctionNo) {
			let query = { };
         query.nftAddress = item.exclusiveNftAddress;
          query.nftId = item.exclusiveNftId;
          query.owner = item.exclusiveOwner
             this.$router.push({ name: "Detail", query });
        } else {
          
          // this.$router.push({
          //   name: "Saledetail",
          //   query: { id: item.exclusiveContractId },
          // });
          if(item.exclusiveLinkUrl&& !item.exclusiveContractId &&item.exclusiveCollectionType == '1')
        {
			let collectsource = "otherpage"
		  
		    this.$router.push({
             path: `/collection/${item.exclusiveLinkUrl}/${collectsource}/${item.exclusiveOwner}`,    
        });
    
       
      }else{
		  debugger
		  let query = { };
		query.contractAddress = item.exclusiveDenom;
		if(item.exclusiveCollectionType == '1' && item.exclusiveTokenId ){
			 query.tokenId = item.couponTokenId;

		}
         
    
        this.$router.push({ name: "Saledetail", query });
      }
      
        }
      } else {
        if (item.couponAuctionNo) {
			let query = { };
        query.contractAddress = item.couponDenom;
       
           
             this.$router.push({ name: "Detail", query });
        
        } else {
         
        if(item.couponLinkUrl && !item.couponTokenId && item.couponCollectionType == '1')
        {
       
		let collectsource = "otherpage"
		  
		    this.$router.push({
             path: `/collection/${item.couponLinkUrl}/${collectsource}/${item.couponDenom}`,    
        });
		
       
	  }
	  else{
	
		  let query = { };
	 query.contractAddress = item.couponDenom;
	 	if(item.couponCollectionType == '1' && item.couponTokenId){
			 query.tokenId = item.couponTokenId;

		}
  
    
        this.$router.push({ name: "Saledetail", query });
      }
        }
      }
    },
			toPage() {
				if (this.marketNftInfo.owner == this.$store.state.did) {
					this.$router.push({
						name: "PersonalPage",
						query: {
							did: this.marketNftInfo.owner
						},
					});
				} else {
					this.$router.push({
						name: "Otherpage",
						query: {
							did: this.marketNftInfo.owner
						},
					});
				}
			},
			collectClick(contractCollectionVO) {
this.$gtag.event('NFT详情页-查看集合', { 'event_category': 'Click', 'event_label': '查看集合' })
				// this.$router.push({name: "CollectionPage", query: { tokenAddress: this.contractCollectionVO.address }});
				let url=""
				if(contractCollectionVO.linkUrl){
					url=contractCollectionVO.linkUrl
				}else{
					url=contractCollectionVO.address
				}
				let collectsource="detaillink"
			debugger
				if(contractCollectionVO.collectionType == '1'){
					  if(contractCollectionVO.contractStatus != 2 && contractCollectionVO.contractStatus != 4 ){
						  	if (this.did == this.$store.state.did) {
					this.$router.push({
						path: `/collection_create/${url}/${collectsource}/${contractCollectionVO.owner}`,
					});
				} else {
					this.$router.push({
						path: `/collection/${url}/${collectsource}/${contractCollectionVO.owner}`,
					});
				}
					  }
				
				}else{
					  if(contractCollectionVO.contractStatus != 2 && contractCollectionVO.contractStatus != 4 ){
					if (this.did == this.$store.state.did) {
					this.$router.push({
						path: `/folder_create/${url}/${collectsource}/${contractCollectionVO.owner}`,
					});
				} else {
					this.$router.push({
						path: `/folder/${url}/${collectsource}/${contractCollectionVO.owner}`,
					});
				}
					  }
				

				}
				
			},
			toCopyLink() {
				// http://web.upticknft.com:8084/#/saledetail?id=7666
				var input = document.createElement("input");
				input.value =
					process.env.VUE_APP_BASE_URL +
					`#/saledetail?id=${this.$route.query.id}`;

				document.body.appendChild(input);
				input.select();
				document.execCommand("copy");
				document.body.removeChild(input);
				this.isCopy = true;
				setTimeout(() => (this.isCopy = false), 100);
				this.$toast("success", this.$t('copt_success'))

				//xxl TODO need to add tip
			},

			toTweet() {
				// alert("abc ...");
				var meta = document.createElement("meta");
				//meta.setAttribute('property', 'og:image');
				meta.setAttribute("name", "twitter:image:src");
				meta.setAttribute(
					"content",
					"https://cdn.cms-twdigitalassets.com/content/dam/developer-twitter/redesign-2021-images/og-social-card/devwebsite_card_tn.jpg.twimg.768.jpg"
				);
				document.getElementsByTagName("head")[0].appendChild(meta);

				//window.open("http://twitter.com/share?text=Im Sharing on Twitter&url=http://stackoverflow.com/users/2943186/youssef-subehi&hashtags=stackoverflow,example,youssefusf")
				window.open(
					"https://twitter.com/intent/tweet?text=Hello%20world&url=http://graphics8.nytimes.com/images/2012/02/19/us/19whitney-span/19whitney-span-articleLarge.jpg"
				);
				//window.open("https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fdeveloper.twitter.com%2F&amp;ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&amp;text=Guides%20%7C%20Docs%20%7C%20Twitter%20Developer%20Platform&amp;url=https%3A%2F%2Fdeveloper.twitter.com%2Fen%2Fdocs%2Ftwitter-for-websites%2Ftweet-button%2Foverview")
			},

			openLargeImg() {
				this.openLargemg = true;
				// this.$refs.largeDialog.playVideo();
			},
			Open(e) {
				this.openLargemg = e;
			},
			buyBtn() {
				// if(!this.$store.state.did){
				// 	 this.openWallet = true
				// 	 return;
				// }else{
				// 	this.openpay = true;
				// }
				this.openpay = true;
			
			},
			OpenPay(e) {
		
				this.openpay = e;
			},
			msgBtn() {
				 this.$gtag.event('NFT详情页-留言', { 'event_category': 'Click', 'event_label': '留言' })
			this.currfriend = {
				fansName: this.marketNftInfo.ownerName,
				fansAddress: this.marketNftInfo.nftOwner.toLowerCase(),
			};


				this.openmsg = true;
			},
			// clickOther: function() {
			// 	console.log("33333333")
			// 	this.openShare = false
			// },
			OpenMsg(e) {
				this.openmsg = e;
			},
			nftPlaintBtn() {
				this.$gtag.event('NFT详情页-投诉NFT', { 'event_category': 'Click', 'event_label': '投诉NFT' })
				this.nftComplaint = true;
				this.clickedComplain = false
			},
			hideNfts(model){
		this.$gtag.event('NFT详情页-隐藏NFT', { 'event_category': 'Click', 'event_label': '隐藏NFT' })
				 this.clickedComplain = false
				 this.hideHandle(model);
			},
			hideCreator(model){
				  this.$gtag.event('NFT详情页-隐藏创作者', { 'event_category': 'Click', 'event_label': '隐藏创作者' })
				 this.clickedComplain = false
				 	 this.hideHandle(model);
			},
			async hideHandle(model){
				let params = {
					authenticationAddress:this.$store.state.did
				}
				if(model == 'nfts'){
					// params.blockNftAddress = this.marketNftInfo.contractAddress
					   if(this.marketNftInfo.collectionType != 1 ){
            		params.blockNftAddress = this.marketNftInfo.contractAddress
          }else{
            	params.blockNftId = this.marketNftInfo.tokenId
          }
				}else{
					params.blockOwner = this.marketNftInfo.nftOwner
				}
				let res = await api.home.hideNfts(params)
				if(res.success){
					this.$toast("success", this.$t("success"))
				}else{
					 this.$toast("error", "Activate faild")
				}

			},

			openNftcComp(e) {
				this.nftComplaint = e;
			},
			mousedCreate(){
				if(this.mousedCreate)
				this.isShowAva = true
			},
			mousedOwner(){
				this.isShowOwner = true
			},
			mousedComplain(){
				this.clickedComplain = true
			},
			mousedContract(){
      this.isshowContract = true

    },
    leaveContract(){
      this.isshowContract = false
    },
			clickOther(){
				this.isShowAva = false
				this.isShowOwner = false
				this.openShare = false
				this.clickedComplain = false
			},
			async buybtn() {
				this.$gtag.event('NFT详情页-购买商品', { 'event_category': 'Click', 'event_label': '购买商品' })
				if(!this.$store.state.did){
						
						window.eventBus.$emit("ShowLogoBox");
						return;
			
				}else{
					this.openbuy = true;
				}
			
				
			},
			openBuy(e) {
				this.openbuy = e;
			},
			async getImage(img) {
				if (img !== null) {
					return await getFileSrc(WORK_KEY, img);
				}
			},
			 mousedlincense(){
      this.isshowLincense = true

    },
    leavelincense(){
      this.isshowLincense = false
    },
			timestamptitleTime(time) {
				let date = new Date(time);
				let Y = date.getFullYear() + ".";
				let M =
					(date.getMonth() + 1 < 10 ?
						"0" + (date.getMonth() + 1) :
						date.getMonth() + 1) + ".";
				let D =
					(date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
				let strDate = Y + M + D;
				return strDate;
			},
			timestampToTime(time) {
				let date = new Date(time);
				let Y = date.getFullYear() + "-";
				let M =
					(date.getMonth() + 1 < 10 ?
						"0" + (date.getMonth() + 1) :
						date.getMonth() + 1) + "-";
				let D =
					(date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
				let h =
					(date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
				let m =
					(date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
					":";
				let s =
					date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
				let strDate = Y + M + D + h + m + s;
				return strDate;
			},
			 async showNoRead(){
    			const json = localStorage.getItem("key_user");
    		if (json) {
					let res2 = await api.home.isNewMessage({address:this.$store.state.did});
					let newMsgCount = res2.data;
					// 有新消息进行弹窗
					if(newMsgCount >0){
						let params={
						pageSize: 10,
						pageNumber: 1,
						owner:this.$store.state.did,
						isRead:false,
						pushTypes:getSettingArr()
					};
      let res = await api.home.sysMessage(params)
      console.log("wxl --- sysMessage",res)
      if(res.data){
         let list = res.data.list
         for (let index = 0; index < list.length; index++) {
           setTimeout(() => {
              this.$mtip({
                title:list[index].pushContract,
              });
           }, 1000);
           
         }
      }      
    }
   }
   window.eventBus.$emit("ViewMessage", true);
   },
		
		},
	};
</script>

<style lang="scss" scoped>
.tab_tit{
	font-weight: bold;
				
				font-size: 25px;
				font-weight: bold;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #270645;
	
				display: block;
}
  .promotion{
        margin-top: 30px;
        width: 342px;
        background-color: #ffffff;
	      border-radius: 5px;
        border: solid 1px #cccccc;
        .contant{
          padding: 10px;
           .participateTitle{
         font-family: Helvetica;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #270645;
        }
        .promotionList{
          width: 100%;
          margin-top:5px;
          .name{
            font-family: Helvetica;
	          font-size: 12px;
            	color: #766983;
            width: 70%;
              text-overflow: ellipsis; /* 溢出显示省略号 */
        overflow: hidden; /* 溢出隐藏 */
        white-space: nowrap;  /* 强制不换行 */

          }
          .used{
            font-family: Helvetica;
	          font-size: 12px;
           color: #cc0000 !important;
             width: 20%;
             color: #270645;

          }
          .unused{
            font-family: Helvetica;
	          font-size: 13px;
         	color: #270645;
                width: 20%

          }
        }
        }
       

      }
	.flex-start-center {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-top: 20px !important;
	}
	 .explaincontract{
      width: 130px;
      height: 40px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: 25px;
    right: 25px;
      z-index: 1;
      span {
        margin: auto;
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
      }

    }

	.item_fonts {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 100px;
	}

	.pointer {
		cursor: pointer;
	}

	.contain {
		width: 100%;
		margin: 0;
		padding: 0;

		.infos {
			.main {
				

				.txt_art-mobile {
					margin: 20px 0 0;
					white-space: normal;
					word-wrap: break-word;
					word-break: break-all;

					span {
						font-weight: bold;
						;
						font-size: 40px;
						font-weight: bold;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #270645;
					}

					p {
						/*width: 748px;*/
						/*width: 61%;*/
						font-family: Helvetica;
						font-size: 16px;
						font-weight: normal;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #270645;
						margin-top: 22px;
					}
				}

				.card_box {
					display: flex;
					width: 771px;
					height: 611px;
					background-color: #f8f6fd;
					border-radius: 5px;
					// padding: 0 156px;
					// text-align: center;

					.mainimg {
						// width: 100%;
						// height: 100%;
						max-height: 611px;
						max-width: 100%;
						width: 100%;
						height: 100%;
						margin: auto;
						object-fit: contain;
						align-items: center;
						/*垂直居中*/
						justify-content: center;
						/*水平居中*/
						//    width: 458px;
						//    height: 610px;
						//    margin: 0 auto;
					}
				}
			}

			.info_box {
				width: 100%;
				.ava {
					.ownerName{
						color: #766983;
						font-size: 14px;
						.name {
								cursor: pointer;
								width: 80px;
						height: 30px;
						 overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                -o-text-overflow:ellipsis;

						font-family: Helvetica;
						font-size: 14px;
						font-weight: normal;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #7800f4;
						padding-left: 4px;

					}
					}
					

					.msg {
						display: inline-block;
						width: 25px;
						height: 24px;
						vertical-align: middle;
					}
				}

				.iris {
					font-weight: bold;
					;
					font-size: 30px;
					font-weight: normal;
					font-stretch: normal;
					letter-spacing: 0px;
					color: #270645;
				}
				.irisOriginalPrice {
					text-decoration: line-through;
					height: 17px;
					font-family: "\5FAE\8F6F\96C5\9ED1";
					font-size: 20px;
					font-weight: normal;
					font-stretch: normal;
					letter-spacing: 0px;
					line-height: 45px;
					color: #766983;
      }

				
				 .usd {
        font-family: Helvetica;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
      }
      .usdOriginalPrice {
        text-decoration: line-through;
        font-family: Helvetica;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #766983;
      }

				.notList {
					height: 25px;
					margin-bottom: 20px;
					font-family: Helvetica;
					font-weight: bold;
					font-size: 30px;
					font-weight: normal;
					font-stretch: normal;
					letter-spacing: 0px;
					color: #270645;
				}

				.sub {
					font-weight: bold;
					;
					font-size: 15px;
					font-weight: normal;
					font-stretch: normal;
					letter-spacing: 0px;
					color: #6f58d9;
				}

				.countdown {
					position: relative;
				

					img {
						cursor: pointer;
						width: 20px;
						height: 20px;
						margin-right: 15px;
					}

					.share {
						width: 166px;
						height: 161px;
						background-image: linear-gradient(#ffffff, #ffffff),
							linear-gradient(#6f58d9, #6f58d9);
						background-blend-mode: normal, normal;
						box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
						border-radius: 5px;
						position: absolute;
						left: -30px;
						top: 30px;
						z-index: 20000;

						img,
						.icon {
							display: inline-block;
							width: 25px;
							height: 25px;
						}

						span {
							font-weight: bold;
							;
							font-size: 13px;
							font-weight: normal;
							font-stretch: normal;
							letter-spacing: 0px;
							color: #270645;
						}
					}

					.showtip {
				
						position: relative;

						.box {
							
							 position: absolute;
							left: -130px;
							top: 27px;
							margin-top: 0px;
							border-radius: 5px;
							background: #ffffff;
							width: 140px;
							height: 120px;
							color: #ffffff;
							font-size: 14px;
							line-height: 34px;
							text-align: left;
							z-index: 99;
							background-blend-mode: normal, normal;
							box-shadow: 0px 0px 9px 0px rgb(0 0 0 / 9%);
							border-radius: 5px;
							.text{
								margin: 10px;
								cursor: pointer;
							}
						}
					}
					// .notshow{
					// 	display: none;
					// }
					// .showtip>.box {
					// 	display: none;
					// }

					// .showtip:hover>.box {
					// 	display: block;
					// }
				}

				.current-bid {
					font-size: 15px;
					font-weight: bold;
					font-stretch: normal;
					margin-top: 10px;
					letter-spacing: 0px;
					color: #270645;

					a {
						text-decoration: none;
					}

					img {
						width: 31px !important;
						height: 31px !important;
						//  margin-right: 16px;
						vertical-align: middle;
					}

					span {
						display: inline-block;
						font-weight: bold;
						;
						font-size: 13px;
						font-weight: normal;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #270645;
					}
				}

				.handle {
					width: 343px;
					position: relative;
					//   @extend .flex-start-center;
					//   justify-content: center;
					//   align-items: center;

					.title {
						font-weight: bold;
						;
						font-size: 13px !important;
						font-weight: bold;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #270645;
						//  margin-top: 22px;
					}

					.bid-btn {
						/*margin-top: 12px;*/
						/*width: 294px;*/
						height: 51px;
						background-image: linear-gradient(90deg,
						    #d300fd 0%,
						    #a439fe 26%,
						    #7471ff 52%,
						    #00fdcf 100%),
						  linear-gradient(#270645, #270645);
						background-blend-mode: normal, normal;
						border-radius: 25px;
						font-weight: bold;
						;
						font-size: 20px;
						font-weight: bold;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #ffffff;
					}

					.sale_out {
						width: 294px;
						height: 51px;
						background-image: linear-gradient(#766983, #766983),
							linear-gradient(#270645, #270645);
						background-blend-mode: normal, normal;
						border-radius: 25px;
					}

					.nosale_out {
						width: 294px;
						height: 51px;
						background-blend-mode: normal, normal;
						border-radius: 25px;
						background-image: linear-gradient(90deg,#d300fd,#a439fe 26%,#7471ff 52%,#00fdcf),linear-gradient(#270645,#270645);
					}

					.phone {
						width: 20px;
						height: 31px;
						display: inline-block;
						// position:absolute;
						// bottom:10px;
						// right:10px;
					}
				}
				.preferentialInfo{
					margin-top: 25px;
					.img{
						img{
							margin-top: 8px;
						}
					}
					.contant{
						margin-left: 10px;
						.time{
							font-family: Helvetica;
							font-size: 13px;
							font-weight: bold;
							font-stretch: normal;
							letter-spacing: 0px;
							color: #270645;

						}
						.des{
							font-family: Helvetica;
							font-size: 13px;
							font-weight: normal;
							font-stretch: normal;
							line-height: 20px;
							letter-spacing: 0px;
							color: #766983;

						}
					}

				}
			 .bindCard {
        justify-content: center;
        // .first{
        //   width: 307px;
        //   background-color: #270645;
        //   border-radius: 3px;
        //   //justify-content:space-between;
        //     .name{
        //       width: 188px;
        //       height: 27px;
        //       font-family:Helvetica;
        //       font-size: 12px;
        //       font-weight: normal;
        //       font-stretch: normal;
        //       line-height: 15px;
        //       letter-spacing: 0px;
        //       color: #c185fe;
        //       text-align: left;
        //       overflow: hidden;
        //       display: -webkit-box;
        //       align-items: center;
        //       -webkit-box-orient: vertical;
        //       -webkit-line-clamp: 3;
        //       margin-left: 6px;
        //       margin-right: 6px;

        //     }
        //     .icon{
        //       width: 33px;
        //       height: 33px;
        //       display: block;
        //       // background-image: linear-gradient(136deg,
        //       //   #d300fd 0%,
        //       //   #a439fe 26%,
        //       //   #7471ff 52%,
        //       //   #00fdcf 100%);

        //     }
        //     .img{
        //       width: 35px;
        //       height: 35px;
        //       border: solid 1px #c185fe;
        //       display: block;
        //     }

        // }
        //  .yes{
        //       	width: 21px;
        //       height: 22px;

        //     }
        .second {
          width: 307px;

          // background-color: #270645;
          border-radius: 3px;
          justify-content: space-left;
          .name {
            width: 235px;
            min-height: 27px;
            font-family: "\5FAE\8F6F\96C5\9ED1";
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 15px;
            letter-spacing: 0px;
            color: #7800f4;
            text-align: left;
            overflow: hidden;
            display: -webkit-box;
            align-items: center;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            margin-left: 12px;
            //margin-right: 6px;
          }
          .bk_title {
			  font-family: Helvetica;
            margin-left: 12px;
            font-size: 13px;
            font-weight: bold;
            font-stretch: normal;
            line-height: 15px;
            letter-spacing: 0px;
            color: #7800f4;
          }

          .icon {
            width: 40px;
            // height: 33px;
            display: block;
          }
          .img {
            width: 23px;
            height: 15px;
            // border: solid 1px #c185fe;
            display: block;
          }
        }
        .cardListbox {
          width: 348px;
          max-height: 399px;
          overflow-y: auto;
          margin-left: 12px;
          // margin-top:6px;
          padding-top: 5px;
          .cards {
            width: 157px;
            height: 61px;
            margin-right: 10px;
            margin-bottom: 6px;
            position: relative;
            padding-left: 10px;
            background-color: #270645;
            border-radius: 3px;
            cursor: pointer;
            img {
              display: block;
              object-fit: cover;
            }
            .cardimg {
              width: 36px;
              height: 36px;
            }
            .txt {
              width: 76px;
              font-family: Helvetica;
              font-size: 12px;
              font-weight: normal;
              font-stretch: normal;
              letter-spacing: 0px;
              color: white;
              text-align: left;
              line-height: 12px;

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
            }
            .isYes {
              width: 21px;
              height: 21px;
              position: absolute;
              top: -5px;
              right: -5px;
            }
          }
        }
        .yes {
          width: 21px;
          height: 22px;
        }
      }
				.createName{
					
						color: #270645;
						font-size: 14px;
						.name {
								cursor: pointer;		
						height: 30px;
						 overflow:hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                -o-text-overflow:ellipsis;

						font-family: Helvetica;
						font-size: 14px;
						font-weight: normal;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #7800f4;
						padding-left: 4px;

					}

					
				}

				.collect {
					width: 252px;
					height: 64px;
					display: flex;
					flex-direction: row;
					align-items: center;
					cursor: pointer;
					position: relative;
      				.complaint{
						position: absolute;
						cursor: pointer;
						top: 40px;
						right: 193px;

					}
					.img {
						width: 61px;
						height: 61px;
						background-color: #270645;
						border-radius: 5px;
						object-fit: cover;
					}

					.info {
						display: flex;
						flex-direction: column;
						margin-left: 10px;
						background-color: unset !important;

						.name {

							font-weight: bold;
							;
							font-size: 13px;
							line-height: 24px;
							color: #270645;

							font-weight: bold;
						}

						.dect {
							max-width: 195px;
							font-family: Helvetica;
							font-size: 13px;
							line-height: 24px;
							font-weight: bold;
							color: #270645;
							overflow: hidden;
							white-space: nowrap;
							text-overflow: ellipsis;
						}

						.num {
							font-family: Helvetica;
							font-size: 13px;
							line-height: 24px;
							color: #766983;
						}
					}
				}
			}
		}

		.txt_art {
			margin: 51px 0 0;
			white-space: normal;
			.lincenseimg{
				position: relative;
				img{
			
					height: 30px;
				}
				 .explainlincense{
      width: 330px;
      min-height: 100px;
      background-color: #270645;
      border-radius: 3px;
      position: absolute;
      top: 35px;
    //   left: 25px;
      z-index: 1;
	  padding: 10px 0px;
      span {
        margin: auto;
        font-family:Helvetica;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 15px;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 13px;
        margin-right: 13px;
      }

    }
			}
			.art-title {
				font-weight: bold;
				font-size: 40px;
				font-weight: bold;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #270645;
				word-wrap:break-word;
			}

			.art-description {
				width: 748px;
				font-family: Helvetica;
				font-size: 16px;
				font-weight: normal;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #270645;
				margin-top: 22px;
				word-wrap:break-word;
			}
			 .title{
      font-family: Helvetica;
	    font-size: 15px;
	    font-weight: bold;
	    font-stretch: normal;
	    letter-spacing: 0px;
	    color: #270645;
    }
    .Properties{
		width: 170px;
      	font-family: Helvetica;
	    font-size: 15px;
	    font-weight: normal;
	    font-stretch: normal;
	    letter-spacing: 0px;
	    color: #270645;
    }
	 .PropertiesValue{
      font-family: Helvetica;
	    font-size: 15px;
	    font-weight: normal;
	    font-stretch: normal;
	    letter-spacing: 0px;
	    color: #270645;
    }
		}

		.history_sale {
			/*width: 1219px;*/
			height: auto; //325px
			margin-bottom: 62px;

			.table_item_font {
				.item_font {
					font-weight: normal;
					font-family: Helvetica;
					font-size: 15px;
					font-stretch: normal;
					letter-spacing: 0px;
					color: #270645;
				}
			}

			span {
				font-weight: bold;
				;
				font-size: 25px;
				font-weight: bold;
				font-stretch: normal;
				letter-spacing: 0px;
				color: #270645;
				margin-bottom: 25px;
				display: block;
			}

			.tab_img {
				display: inline-block;
				position: relative;
				top: 10px;
				margin-top: 10px;
			}

			.tab-span {
				display: inline-block;
				position: relative;
				top: 0;
				left: 11px;
				font-size: 14px;
				color: #270645;
				font-weight: normal;
			}
		}

		.sale_card {
			// width: 1349px;
			height: auto;
			margin-bottom: 100px;

			.sale_tit {
				margin-bottom: 17px;
				position: relative;

				img {
					width: 42px;
					height: 42px;
				}

				span {
					display: inline-block;
					position: relative;
					top: 0px;
					left: 13px;
					font-weight: bold;
					;
					font-size: 15px;
					font-weight: normal;
					font-stretch: normal;
					letter-spacing: 0px;
					color: #270645;
				}

				.rgh {
					position: absolute;
					right: 0;
				}
			}

			// .cardbox {
			//   // margin:38px 0;
			// }
		}

		&.mobile {
			padding-bottom: 20px;

			.infos {
				width: 100%;

				.main {
					width: 100%;

					.card_box {
						width: 100%;
						height: unset;
						padding: 0 !important;
					}

					.txt_art {
						span {
							font-size: 38px;
						}
					}
				}

				.info_box {
					.ava {
						display: flex !important;
						flex-direction: column !important;
						.ownerName {
							align-items: center;
							.name {
								display: flex;
								align-items: center;
							}
						}
					}
					.createName {
						margin-top: 15px;
					}
					.countdown {
						margin-top: 15px;
						.share {
							width: 166px;
							height: 161px;
							background-image: linear-gradient(#ffffff, #ffffff),
								linear-gradient(#6f58d9, #6f58d9);
							background-blend-mode: normal, normal;
							box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
							border-radius: 5px;
							position: absolute;
							left: 130px;
							top: 0;
							z-index: 20000;

							img,
							.icon {
								display: inline-block;
								width: 25px;
								height: 25px;
							}

							span {
								font-weight: bold;
								;
								font-size: 13px;
								font-weight: normal;
								font-stretch: normal;
								letter-spacing: 0px;
								color: #270645;
							}
						}
					}

					.msg {
						margin-left: 135px;
					}

					.handle {
						max-width: 100%;

						.bid-btn {
							width: 240px !important;
						}
					}
				}
			}

			.history_sale {
				.table_font {
					font-weight: bold;
					font-family: Helvetica;
					font-size: 14px;
					font-stretch: normal;
					letter-spacing: 0px;
					color: #270645;
				}

				.table_item_font {
					.item_font {
						font-weight: normal;
						font-family: Helvetica;
						font-size: 11px !important;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #270645;
					}
				}
			}

			.sale_card {
				width: 100%;

				.cardbox {
					width: 100%;
					margin-bottom: 38px;
				}
			}
		}
	}

	.elevation-1 {
		border-bottom: 1px solid #eee;
	}

	.history_sale ::v-deep .elevation-1 {
		height: 51px;
		box-shadow: 2px 2px 5px #ccc !important;
		background-blend-mode: normal, normal;
		//box-shadow: 0px 0px 3px 0px
		//	rgba(0, 0, 0, 0.09);
		border-radius: 5px;
	}

	.overflow {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		display: flex;
		align-items: center;

		span {
			margin-top: 25px;
		}
	}
</style>
